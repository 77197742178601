body {
    background: url("/public/back.jpg") center center no-repeat fixed;
    background-size: cover;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
    color: #61dafb;
}

.icon.circular {
    background-color: #009878;
}

#credit {
    color: #000; /* Fallback for older browsers */
    color: rgba(255, 255, 255, 0.2);
    font-size: 11px;
    position: fixed;
    right: 5px;
    bottom: 50px;
}

.fade-enter {
    opacity: 0;
    transform: translateX(5vw);
}

.fade-enter-active {
    opacity: 1;
    transform: translateX(0%);
}

.fade-exit-active {
    opacity: 0;
    transform: translateX(-5vw);
}

.fade-enter-active,
.fade-exit-active {
    transition: opacity 500ms, transform 300ms;
}

#cst-container {
    padding-top: 12vh;
    padding-bottom: 5em;
}

#cst-segment {
    padding-top: 100px;
    padding-bottom: 100px;
    padding-left: 150px;
    padding-right: 150px;
    background-color: white;
    border-radius: 4px;

    transition-property: height;
    transition-duration: 2s;
}

#logo-icon {
    width: 250px;
}

#logo-icon2 {
    width: 150px;
}

#footer-style {
    font-size: 14px;
    padding-bottom: 1em;
    position: fixed
}

@media only screen and (max-width: 1000px) {
    #cst-container {
        padding-top: 90px;
        padding-bottom: 5em;
    }

    #cst-segment {
        padding-top: 40px;
        padding-bottom: 40px;
        padding-left: 40px;
        padding-right: 40px;
    }
    #logo-icon {
        width: 250px;
    }
    #logo-icon2 {
        width: 150px;
    }
    #footer-style {
        font-size: 14px;
        padding-bottom: 1em;
        position: fixed
    }
}

@media only screen and (max-width: 600px) {
    .fade-enter {
        opacity: 0;
        transform: translateX(10px);
    }

    .fade-enter-active {
        opacity: 1;
        transform: translateX(0%);
    }

    .fade-exit-active {
        opacity: 0;
        transform: translateX(-10px);
    }

    #cst-container {
        padding-top: 90px;
        padding-bottom: 5em;
    }

    #cst-segment {
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 10px;
        padding-right: 10px;
    }

    #logo-icon {
        width: 120px;
    }
    #logo-icon2 {
        width: 100px;
    }

    #footer-style{
        font-size: 10px; 
        padding-bottom: 1em;
        position: fixed
    }
}
